import React from 'react';
import { graphql } from 'gatsby';
import Img, { FixedObject } from 'gatsby-image';
import Layout from '../components/Layout';
import MaskText from '../components/MaskText';
import { SMALL_VIEW_PORT, SECONDARY_COLOR } from '../constants/style';
import styled from '../utils/styled-components';

type QueryData = {
  file: {
    childImageSharp: {
      fixed: FixedObject;
    };
  };
};

const MePage: React.FCWithQuery<QueryData> = ({
  data
}: {
  data: QueryData;
}) => {
  const { file } = data;
  return (
    <Layout title="ME">
      <Wrapper>
        <AuthorImage
          fixed={file.childImageSharp.fixed}
          backgroundColor={SECONDARY_COLOR}
        />
        <Description>
          <Position>Illustrator / Frontend UX Engineer / Web Designer</Position>
          <MaskText maskColor={SECONDARY_COLOR}>
            <Name>Nagi Shiose</Name>
          </MaskText>
          <NameJP>汐瀬 なぎ</NameJP>
          <Socials>
            <SocialItem>
              <a href="https://twitter.com/polyxx">Twitter</a>
            </SocialItem>
            <SocialItem>
              <a href="https://pixiv.me/polyxx">Pixiv</a>
            </SocialItem>
            <SocialItem>
              <a href="https://github.com/polyxx">GitHub</a>
            </SocialItem>
          </Socials>
          <Heading>Biography</Heading>
          <Biography>
            フロントエンドUXエンジニア。サークル『Polychrome』『Monochrome』代表。
            <br />
            ReactをメインとしたWebアプリケーション開発の他、アプリケーションのデザイン制作、UI/UXの改善も行う。
            <br />
            同人活動として技術書の執筆、イラスト・漫画制作、Webサイト制作など、活動は多岐にわたる。
            <br />
            新しいものや面白いものに触れるのが好き。
          </Biography>
          <Heading>Works</Heading>
          <Works>
            <li>
              2019『やさしく学べるWeb
              Components』（インプレスR＆D刊）表紙イラスト
            </li>
            <li>
              2018『ネコミミでもわかるフロントエンド開発環境構築』（インプレスR＆D刊）
            </li>
          </Works>
        </Description>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "me.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 0;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    flex-direction: row;
  }
`;

const AuthorImage = styled(Img)`
  flex-shrink: 0;
  width: 300px;
  margin: 0 auto;
  border-radius: 2px;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    margin: 0;
  }
`;

const Description = styled.div`
  position: relative;
  padding: 1rem 0;

  @media (min-width: ${SMALL_VIEW_PORT}) {
    padding: 1rem 2rem;
  }
`;

const Position = styled.h6`
  margin: 0 0 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 100;
`;

const Name = styled.h1`
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
`;

const NameJP = styled.h2`
  margin: 0.5rem 0 0;
  font-size: 1.1rem;
  font-weight: 100;
`;

const Socials = styled.ul`
  display: flex;
  margin: 1.25rem 0 0;
  list-style: none;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
  font-weight: 100;
`;

const SocialItem = styled.li`
  position: relative;
  margin: 0 0 0 0.5rem;
  padding-left: 0.5rem;
  transition: color 0.2s ease-out;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 1px;
    height: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.4);
  }

  &:hover {
    color: rgb(0, 0, 0);
  }

  &:first-of-type {
    margin-left: 0;
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  > a {
    color: inherit;
  }
`;

const Heading = styled.h3`
  margin: 1.5rem 0 1rem;
  color: ${props => props.theme.secondary};
  font-weight: 100;
`;

const Biography = styled.p`
  font-size: 0.9rem;
`;

const Works = styled.ul`
  font-size: 0.9rem;
`;

export default MePage;
